import React from 'react'
import { Icon } from '@iconify/react'
import noDataimg from '../../assets/images/No data-cuate.png'
import Pagination from '../../common/Pagination'
import { Link } from 'react-router-dom'
import TableSkeleton from '../../utils/skeletons/TableSkeleton'
import Skeleton from 'react-loading-skeleton'
function CourseListTable(
    {
        setShowModal,
        courseTabledata,
        page,
        setpage,
        length,
        status,
        username,
        setstatus,
        setusername,
        seteditModal,
        getAllcourse,
        seteditUserId,
        IsApiFetching
    }
) {
    const coureNameChangeHandler = (e) => {
        setusername(e.target.value)
        setpage(1)
    }
    return (
        <div className="card h-100">
            <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
                <div className="d-flex align-items-center flex-wrap gap-3">

                    <form className="navbar-search">
                        <input type="text" className="bg-base h-40-px w-auto" name="search" placeholder="Enter course name" value={username}
                            onChangeCapture={coureNameChangeHandler} />
                        <Icon icon="ion:search-outline" className="icon"></Icon>
                    </form>

                    {/* <select className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px"
                        value={status}
                        onClick={(e) => setstatus(e.target.value)}>
                        {
                            [{ title: 'Status', value: '' },
                            { title: 'Active', value: 1 },
                            { title: 'Inactive', value: 0 }].map((item) => (
                                <option value={item.value}>{item.title}</option>
                            ))
                        }
                    </select> */}
                </div>
                {
                    IsApiFetching ?
                        <Skeleton width={200} height={50} baseColor='#f5f6fa' />
                        :
                        <a href="#" className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2" onClick={() => setShowModal(true)}>
                            <Icon icon="ic:baseline-plus" className="icon text-xl line-height-1"></Icon>
                            Add New Course
                        </a>
                }
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-style mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Course</th>
                                <th>Rating</th>
                                <th>Tutor</th>
                                <th>No of Students</th>
                                <th>No of orders</th>
                                <th></th>
                            </tr>
                        </thead>
                        {
                            IsApiFetching ?
                                <TableSkeleton rowCount={6} colCount={6} />

                                :
                                <tbody>
                                    {

                                        courseTabledata?.tabledata?.length > 0 ?
                                            courseTabledata?.tabledata?.map((item, index) => {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>{item.rating}<Icon icon="material-symbols:star" className='star-icon' width='24' height='24'></Icon> </td>
                                                        <td>{item.tutor}</td>
                                                        <td>{item.studentCount}</td>
                                                        {/* <td><span className="bg-success-focus px-3">Active</span></td> */}
                                                        <td>{item.orderCount}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-10">
                                                                <button
                                                                    type="button"
                                                                    className="card-edit-button text-success-600"
                                                                    onClick={() => seteditModal(item)}
                                                                >
                                                                    <Icon
                                                                        icon="lucide:edit"
                                                                        className="icon text-lg line-height-1"
                                                                    ></Icon>
                                                                </button>
                                                                {/* <button
                                                            type="button"
                                                            className="card-delete-button text-danger-600"
                                                        >
                                                            <Icon
                                                                icon="fluent:delete-24-regular"
                                                                className="icon text-lg line-height-1"
                                                            ></Icon>
                                                        </button> */}
                                                                <Link
                                                                    to={`/module/${item.id}`}
                                                                    className="btn rounded-pill btn-outline-neutral-900 radius-8 px-10 py-11 font-13"
                                                                >
                                                                    Add Modules
                                                                </Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td colSpan={5} className="text-center">
                                                    <img src={noDataimg} className="w-50" alt="No Data" />
                                                </td>
                                            </tr>

                                    }

                                </tbody>
                        }

                    </table>
                </div>
                <Pagination
                    totalEntries={courseTabledata?.totalCount}
                    entriesPerPage={length}
                    currentPage={page}
                    onPageChange={setpage}
                />
            </div>
        </div>

    )
}

export default CourseListTable