import { Icon } from '@iconify/react'
import React from 'react'
import noDataimg from '../../assets/images/No data-cuate.png'
import avatarImg from '../../assets/images/users/user3.png'
import { Link } from 'react-router-dom'
import courseImg from '../../assets/images/online-course.png'
import Skeleton from 'react-loading-skeleton'

export default function Courses({ CourseData, highRateApiLoading }) {

    return (
        <div className="col-xxl-6 col-lg-6">
            <div className="card h-100">
                <div className="card-body">
                    <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-20">
                        <h6 className="mb-2 fw-bold text-lg mb-0">Courses</h6>
                        <Link to="/course" className="text-primary-600 hover-text-primary d-flex align-items-center gap-1">
                            {highRateApiLoading ? <Skeleton width={50} baseColor='#f5f6fa' /> : 'View All'}
                            <Icon icon="solar:alt-arrow-right-linear" className="icon"></Icon>
                        </Link>
                    </div>

                    <div className="mt-32">
                        {
                            highRateApiLoading ?
                                Array.from({ length: 5 }).map((_, index) => (
                                    <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
                                        <div className="d-flex align-items-center gap-2">
                                            <Skeleton className="w-40-px h-40-px radius-8 flex-shrink-0" circle={true} baseColor='#f5f6fa' />
                                            <div className="flex-grow-1">
                                                <h6 className="text-md mb-0 fw-normal"><Skeleton width={100} baseColor='#f5f6fa' /></h6>
                                                <span className="text-sm text-secondary-light fw-normal"><Skeleton width={50} baseColor='#f5f6fa' /></span>
                                            </div>
                                        </div>
                                        <span className="text-primary-light text-md fw-medium"><Skeleton width={100} baseColor='#f5f6fa' /></span>
                                    </div>
                                ))
                                :
                                CourseData?.tabledata?.length > 0 ?
                                    CourseData?.tabledata?.map((item, index) => (
                                        <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={item.image ?? courseImg} alt="" className="w-40-px h-40-px radius-8 flex-shrink-0" />
                                                <div className="flex-grow-1">
                                                    <h6 className="text-md mb-0 fw-normal">{item.title}</h6>
                                                    <span className="text-sm text-secondary-light fw-normal">{item.course}</span>
                                                </div>
                                            </div>
                                            <span className="text-primary-light text-md fw-medium">
                                                <span>
                                                    Rating : {item.rating}<Icon icon="material-symbols:star" className='star-icon' width='24' height='24'></Icon>
                                                </span>
                                            </span>
                                        </div>
                                    ))
                                    :
                                    <center>
                                        <img src={noDataimg} className='w-75' />
                                    </center>

                        }
                    </div>
                </div>
            </div>
        </div>

    )
}
