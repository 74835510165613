import React from 'react'
import noDataimg from '../../assets/images/No data-cuate.png'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'

export default function Transactions({ transactionsData, setTransactionPeriod, transactionPeriod, tranApiLoading }) {
  return (
    <div className="col-xxl-3">
      <div className="card h-100">
        <div className="card-body">
          <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between">
            <h6 className="mb-2 fw-bold text-lg">{tranApiLoading ? <Skeleton width={100} /> : 'Transactions'}</h6>
            <div>
              {
                tranApiLoading ?
                  <Skeleton width={100} height={30} baseColor='#f5f6fa' />
                  :
                  <select className="form-select form-select-sm w-auto bg-base border text-secondary-light"
                    onChange={(e) => setTransactionPeriod(e.target.value)}
                    value={transactionPeriod}>
                    {
                      [
                        {
                          title: 'This Month',
                          value: 'current'
                        },
                        {
                          title: 'Last Month',
                          value: 'previous'
                        }
                      ].map((option) => (
                        <option value={option.value}>{option.title}</option>
                      ))
                    }
                  </select>
              }
            </div>
          </div>

          <div className="mt-32">
            {
              tranApiLoading ?
                Array.from({ length: 10 }).map((_, index) => (
                  <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
                    <div className="d-flex align-items-center gap-2">
                      <div className="flex-grow-1">
                        <h6 className="text-md mb-0 fw-normal"><Skeleton width={200} baseColor='#f5f6fa' /></h6>
                      </div>
                    </div>
                    <span >
                      <Skeleton width={50} baseColor='#f5f6fa' />
                    </span>
                  </div>
                ))
                :
                transactionsData.length > 0 ?
                  transactionsData?.map((transaction, index) => (

                    <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
                      <div className="d-flex align-items-center gap-2">
                        <div className="flex-grow-1">
                          <h6 className="text-md mb-0 fw-normal">{moment(transaction.createdAt).format('DD-MM-YYYY')}</h6>
                        </div>
                      </div>
                      <span className={`${transaction.type == 'credit' ? 'text-success-main' : 'text-danger-main'} text-md fw-medium`}>
                        {transaction.type == 'credit' ? '+' : '-'}
                        ${transaction.amount}
                      </span>
                    </div>
                  ))
                  :
                  <img src={noDataimg} />
            }
          </div>
        </div>
      </div>
    </div>

  )
}
