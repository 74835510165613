import React, { createContext, useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SetUserType, SetloginStatus } from '../redux/reducers/slices/loginSlice';
import AuthApis from '../queries/auth';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); 
    const [userType, setUserType] = useState(null);
    const performLogout=AuthApis.Logout()
    const dispatch = useDispatch();

    useEffect(() => {
        const loginStatus = localStorage.getItem('loginStatus') === 'true';
        const storedUserType = localStorage.getItem('userType');
        setIsAuthenticated(loginStatus);
        setUserType(storedUserType);
        dispatch(SetloginStatus(loginStatus));
        dispatch(SetUserType(storedUserType));
    }, [dispatch]);

    const login = (userType) => {
        dispatch(SetloginStatus(true));
        dispatch(SetUserType(userType));
        localStorage.setItem('loginStatus', 'true');
        localStorage.setItem('userType', userType);
        setIsAuthenticated(true);
        setUserType(userType);
    };

    const logout = () => {
        localStorage.removeItem('loginStatus');
        localStorage.removeItem('userType');
        setIsAuthenticated(false);
        setUserType(null);
        performLogout.mutate()
    };

    if (isAuthenticated === null) {
        return ; 
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, userType, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
