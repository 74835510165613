import { Icon } from '@iconify/react'
import React from 'react'
import noDataimg from '../../assets/images/No data-cuate.png'
import defaultAvatar from '../../assets/images/users/user1.png'
import { Link } from 'react-router-dom'
import TableSkeleton from '../../utils/skeletons/TableSkeleton'


export default function RecentPurchases({ recentPurchaseData, repurchaseApiLoading }) {

  return (
    <div className="col-xxl-9 col-lg-6">
      <div className="card h-100">
        <div className="card-body p-24">
          <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-20">
            <h6 className="mb-2 fw-bold text-lg mb-0">Purchases</h6>
            <Link to='/sales-report' className="text-primary-600 hover-text-primary d-flex align-items-center gap-1">
              View All
              <Icon icon="solar:alt-arrow-right-linear" className="icon" />
            </Link>
          </div>
          <div className="table-responsive scroll-sm">
            <table className="table bordered-table mb-0">
              <thead>
                <tr>
                  <th scope="col">Students</th>
                  <th scope="col">Invoice</th>
                  {/* <th scope="col">Items</th> */}
                  <th scope="col">Amount</th>
                  <th scope="col" className="text-center">Status</th>
                </tr>
              </thead>
              {
                repurchaseApiLoading ?
                  <TableSkeleton rowCount={5} colCount={4} />
                  :
                  <tbody>
                    {
                      recentPurchaseData?.tabledata?.length > 0 ?
                        recentPurchaseData?.tabledata.map((item) => {
                          return (
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">

                                  <img src={item.userImage ?? '/image/user.png'} alt="" className="flex-shrink-0 me-12 radius-8" style={{ width: '40px' }} />
                                  <span className="text-lg text-secondary-light fw-semibold flex-grow-1">{item.username}</span>
                                </div>
                              </td>
                              <td className='invoice_link'><Link to={`/invoice/${item?.invoice}`}>{item.invoice}</Link></td>
                              {/* <td>{item.course}</td> */}
                              <td>${item.amount}</td>
                              <td className="text-center">
                                <span className={`${item.status === 'paid' ? 'bg-success-focus' : 'bg-warning-focus'}
                             ${item.status === 'paid' ? 'text-success-main' : 'text-secondary-light'} px-24 py-4 rounded-pill fw-medium text-sm`}>{item.status}</span>
                              </td>
                            </tr>
                          )
                        })
                        :
                        <tr>
                          <td colSpan={5} style={{ textAlign: "start", verticalAlign: "middle" }}>
                            <img src={noDataimg} className='w-25' />
                          </td>
                        </tr>
                    }

            
                  </tbody>
              }

            </table>
          </div>
        </div>
      </div>
    </div>

  )
}
