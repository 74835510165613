import React from 'react'
import Skeleton from 'react-loading-skeleton'
function ListRankedTutors({ topTutorsData, topTutorApiLoading }) {

    return (
        <div className="admin_content_row">
            <h6>{topTutorApiLoading ? <Skeleton width={100}  /> : 'Top Tutors'}</h6>
            <div className="users_list_section">
                <div className="users_list_row">
                    {
                        topTutorApiLoading ?
                            Array.from({ length: 5 }).map((_, index) => (
                                <div className="users_list_box">
                                    <Skeleton className="users_list_box_img" circle={true} width={100} height={100} baseColor='#f5f6fa'/>
                                    <div className="users_list_box_cnt">
                                        <h6><Skeleton width={150} baseColor='#f5f6fa'/></h6>
                                        <p><Skeleton width={50} baseColor='#f5f6fa'/></p>
                                        <h6><Skeleton width={100} baseColor='#f5f6fa'/></h6>
                                    </div>
                                </div>
                            ))

                            :

                            topTutorsData?.tabledata?.map((item) => {
                                return (
                                    <div className="users_list_box">
                                        <div className="users_list_box_img">
                                            <img src={item.image ?? 'image/user.png'} />
                                        </div>
                                        <div className="users_list_box_cnt">
                                            <h6>{item.fullName}</h6>
                                            <p>{item.course}</p>
                                            <h6>Course Count: {item.courseCount}</h6>
                                        </div>
                                    </div>
                                )
                            })
                    }


                </div>
            </div>
        </div>

    )
}

export default ListRankedTutors