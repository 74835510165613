import React from 'react'
import Skeleton from 'react-loading-skeleton'
function Addmodule({ setshowAddModuleModal, moduleApiLoading }) {
    return (
        <div className="col-md-12 mt-3" id="module_add-before_item">
            {
                moduleApiLoading ?
                    <Skeleton height={30} width={100}/>
                    :
                    <button
                        className="btn btn-primary py-4 d-flex gap-2"
                        // data-bs-toggle="modal"
                        // data-bs-target="#staticBackdrop-add-module"
                        onClick={() => setshowAddModuleModal(true)}
                    >
                        Add Module
                    </button>

            }

        </div>
    )
}

export default Addmodule