import React from 'react'
import { Icon } from '@iconify/react'
import courseImg from '../../assets/images/online-course.png'
import Skeleton from 'react-loading-skeleton'
function CourseList({ courseListdata, highRateCourseApiLoading }) {
    return (
        <div className="admin_content_row">
            <h6>{highRateCourseApiLoading ? <Skeleton width={100} /> : 'Course Preview'}</h6>
            <div className="users_list_section">
                <div className="users_list_row">


                    {
                        highRateCourseApiLoading ?
                            Array.from({ length: 5 }).map((_, index) => (
                                <div className="users_list_box">
                                    <Skeleton className="users_list_box_img" circle={true} width={100} height={100} baseColor='#f5f6fa' />
                                    <div className="users_list_box_cnt">
                                        <h6><Skeleton width={150} baseColor='#f5f6fa' /></h6>
                                        <h6><Skeleton width={100} baseColor='#f5f6fa' /></h6>
                                    </div>
                                </div>
                            ))

                            :
                            courseListdata?.tabledata &&
                            courseListdata?.tabledata.map((item, index) => {
                                return (
                                    <div className="users_list_box">
                                        <div className="users_list_box_img">
                                            <img src={item.image ?? courseImg} />
                                        </div>
                                        <div className="users_list_box_cnt">
                                            <h6>{item.title}</h6>
                                            <h6>
                                                Rating: <Icon icon="material-symbols:star" className='star-icon'></Icon> {item.rating}
                                            </h6>
                                        </div>
                                    </div>
                                )
                            })
                    }

                </div>
            </div>
        </div>

    )
}

export default CourseList