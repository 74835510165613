import { Icon } from '@iconify/react'
import React from 'react'
import noDataimg from '../../assets/images/No data-cuate.png'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
function Students({ topStudents, topStudentApiLoading }) {
  return (
    <div className="col-xxl-6 col-lg-6">
      <div className="card h-100">
        <div className="card-body">
          <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between mb-20">
            <h6 className="mb-2 fw-bold text-lg mb-0">Students</h6>
            <Link to="/students" className="text-primary-600 hover-text-primary d-flex align-items-center gap-1">
              {topStudentApiLoading ? <Skeleton width={50} baseColor='#f5f6fa' /> : 'View All'}
              <Icon icon="solar:alt-arrow-right-linear" className="icon"></Icon>
            </Link>
          </div>

          <div className="mt-32">
            {

              topStudentApiLoading ?
                Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
                    <div className="d-flex align-items-center gap-2">
                      <Skeleton className="w-40-px h-40-px radius-8 flex-shrink-0" circle={true} baseColor='#f5f6fa' />
                      <div className="flex-grow-1">
                        <h6 className="text-md mb-0 fw-normal"><Skeleton width={100} baseColor='#f5f6fa' /></h6>
                        <span className="text-sm text-secondary-light fw-normal"><Skeleton width={50} baseColor='#f5f6fa' /></span>
                      </div>
                    </div>
                    <span className="text-primary-light text-md fw-medium"><Skeleton width={100} baseColor='#f5f6fa' /></span>
                  </div>
                ))
                :
                topStudents?.tabledata?.length > 0 ?
                  topStudents?.tabledata?.map((student, index) => (
                    <div key={index} className="d-flex align-items-center justify-content-between gap-3 mb-32">
                      <div className="d-flex align-items-center gap-2">
                        <img src={student.image ?? 'image/user.png'} alt={student.name} className="w-40-px h-40-px radius-8 flex-shrink-0" />
                        <div className="flex-grow-1">
                          <h6 className="text-md mb-0 fw-normal">{student.username}</h6>
                          <span className="text-sm text-secondary-light fw-normal">{student.username}</span>
                        </div>
                      </div>
                      <span className="text-primary-light text-md fw-medium">Certificate Count: {student.certificates}</span>
                    </div>
                  ))
                  :
                  <center>
                    <img src={noDataimg} className='w-75' />
                  </center>
            }
          </div>
        </div>
      </div>
    </div>

  )
}

export default Students