import React, { useState } from 'react'
import CourseDetails from '../../components/courses/CourseDetails'
import CourseModules from '../../components/courses/CourseModules'
import AddSubject from '../../components/courses/AddSubject'
import Addmodule from '../../components/courses/Addmodule'
import VideoPreview from '../../components/courses/VideoPreview'
import AddmoduleModal from '../../components/courses/AddmoduleModal'
import AddSubjectModal from '../../components/courses/AddSubjectModal'
import { useParams } from 'react-router-dom'
import CourseApis from '../../queries/course'
import EditSubjectModal from '../../components/courses/EditSubjectModal'
import EditModuleModal from '../../components/courses/EditModuleModal'

function Module() {
    
    const [showVideoPreviewModal, setshowVideoPreviewModal] = useState(false)
    const [showAddModuleModal, setshowAddModuleModal] = useState(false)
    const [showAddSubjectModal, setshowAddSubjectModal] = useState(false)
    const [videoUrl, setvideoUrl] = useState('')
    const [moduleId, setmoduleId] = useState()
    const [subEditData, setsubEditData] = useState()
    const [showSubeditModal, setshowSubeditModal] = useState(false)
    const [moduleEditData, setmoduleEditData] = useState()
    const [openModuleEditModal, setopenModuleEditModal] = useState(false)
    const handleCloseVidPreview = () => {
        setshowVideoPreviewModal(false)
    }
    const handleCloseAddmoduleModal = () => {
        setshowAddModuleModal(false)
    }
    const handleCloseAddsubjectModal = () => {
        setshowAddSubjectModal(false)
    }
    const handleCloseEditsubjectModal = () => {
        setshowSubeditModal(false)
    }
    const handleCloseEditmoduleModal = () => {
        setopenModuleEditModal(false)
    }
    const [length, setlength] = useState(5)
    const [page, setpage] = useState(1)

    const openSubAddmodal = (moduleId) => {
        setmoduleId(moduleId)
        setshowAddSubjectModal(true)

    }
    const { id } = useParams()
    const { data: moduleData, refetch: getAllmodules,isLoading:moduleApiLoading } = CourseApis.GetCourseModule(id, page, length)

    const getEditdata = (data, moduleId) => {
        setsubEditData(data)
        setshowSubeditModal(true)
        setmoduleId(moduleId)
    }
    const getModuleEditData = (data) => {
        setmoduleEditData(data)
        setopenModuleEditModal(true)


    }
    return (
        <>
            <div className="dashboard-main-body">
                <CourseDetails 
                setshowAddSubjectModal={setshowAddSubjectModal} 
                moduleData={moduleData}
                moduleApiLoading={moduleApiLoading} />

                <CourseModules
                    setshowVideoPreviewModal={setshowVideoPreviewModal}
                    setshowAddSubjectModal={setshowAddSubjectModal}
                    setvideoUrl={setvideoUrl}
                    moduleData={moduleData}
                    page={page}
                    setpage={setpage}
                    length={length}
                    getAllmodules={getAllmodules}
                    openSubAddmodal={openSubAddmodal}
                    editHandler={getEditdata}
                    openEditModule={getModuleEditData}
                    moduleApiLoading={moduleApiLoading}

                />
                {/* <AddSubject setshowAddSubjectModal={setshowAddSubjectModal} /> */}
                <Addmodule setshowAddModuleModal={setshowAddModuleModal} moduleApiLoading={moduleApiLoading}/>
            </div>
            {
                videoUrl &&
                <VideoPreview
                    show={showVideoPreviewModal}
                    handleClose={handleCloseVidPreview}
                    videoUrl={videoUrl}
                />
            }

            <AddmoduleModal
                show={showAddModuleModal}
                handleClose={handleCloseAddmoduleModal}
                getAllmodules={getAllmodules}
                courseId={id}
            />
            <AddSubjectModal
                show={showAddSubjectModal}
                handleClose={handleCloseAddsubjectModal}
                getAllmodules={getAllmodules}
                courseId={id}
                moduleId={moduleId}
            />
            {
                subEditData &&
                <EditSubjectModal
                    show={showSubeditModal}
                    handleClose={handleCloseEditsubjectModal}
                    getAllmodules={getAllmodules}
                    courseId={id}
                    moduleId={moduleId}
                    editData={subEditData}
                />
            }
            {
                moduleEditData &&
                <EditModuleModal
                    show={openModuleEditModal}
                    handleClose={handleCloseEditmoduleModal}
                    getAllmodules={getAllmodules}
                    courseId={id}
                    editData={moduleEditData}
                />
            }

        </>

    )
}

export default Module