import React, { useState } from 'react'
import RevenueReport from '../../components/dashboard/RevenueReport'
import RecentPurchases from '../../components/tutor-dashboard/RecentPurchases'
import Transactions from '../../components/tutor-dashboard/Transactions'
import Courses from '../../components/tutor-dashboard/Courses'
import DashboardApis from '../../queries/dashboard'
import Students from '../../components/tutor-dashboard/Students'
import StudentStatistics from '../../components/tutor-dashboard/StudentStatistics'
import Tiles from '../../components/tutor-dashboard/Tiles'
import TutorDashboardApis from '../../queries/tutor.dashboard'
import CourseApis from '../../queries/course'

function Dashboard() {
    const [period, setperiod] = useState('month')
    const [transactionPeriod, setTransactionPeriod] = useState('current')
    const [revenuePeriod, setrevenuePeriod] = useState('year')
    const { data: tiles, isLoading: tilesApiLoading } = TutorDashboardApis.GetTiles()
    const { data: dountChartData, isLoading: donutChartApiLoading } = TutorDashboardApis.GetStudentStatics(period)
    const { data: recentPurchaseData, isLoading: repurchaseApiLoading } = TutorDashboardApis.GetPurchaseList()
    const { data: topStudents,isLoading:topStudentApiLoading } = TutorDashboardApis.GetStudentsList()
    const { data: transactionsData,isLoading:tranApiLoading } = TutorDashboardApis.GetTransactions(transactionPeriod)
    const { data: CourseData,isLoading:highRateApiLoading } = CourseApis.GetHighRateCourses()
    return (
        <div class="dashboard-main-body" data-aos="fade-right">
            <div class="row gy-4">

                <div className="col-xxl-12">
                    <div className="card radius-8 border-0">
                        {/* <span></span> */}
                        <div className="row">


                            <Tiles
                                tilesData={tiles?.data}
                                tilesApiLoading={tilesApiLoading}
                            />

                            <StudentStatistics
                                dountChartData={dountChartData?.data}
                                setperiod={setperiod}
                                period={period}
                                donutChartApiLoading={donutChartApiLoading}
                            />

                        </div>
                    </div>
                </div>


                <RecentPurchases recentPurchaseData={recentPurchaseData?.data} repurchaseApiLoading={repurchaseApiLoading} />
                <Transactions
                    transactionsData={transactionsData?.data}
                    setTransactionPeriod={setTransactionPeriod}
                    transactionPeriod={transactionPeriod}
                    tranApiLoading={tranApiLoading}
                />


                <Courses CourseData={CourseData?.data} highRateApiLoading={highRateApiLoading}/>
                <Students topStudents={topStudents?.data} topStudentApiLoading={topStudentApiLoading}/>
            </div>
        </div>
    )
}

export default Dashboard